import React from 'react';
import { Provider } from 'react-redux';
import GlobalStyles from 'styles/GlobalStyles';

import Layout from 'components/Layout';
import Homepage from 'pages/Homepage';

import data from './data.json';
import createStore from './store';

const store = createStore(data);

const App = () => (
  <Provider store={store}>
    <Layout>
      <Homepage />
      <GlobalStyles />
    </Layout>
  </Provider>
);

export default App;
