import React from 'react';
import Filters from './Filters';

const Homepage = () => (
  <>
    <Filters />
    <div className="App">React</div>
  </>
);

export default Homepage;
