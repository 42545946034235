import styled from 'styled-components';

import closeIcon from './closeIcon@3x.png';

export const FiltersContainer = styled.div`
  margin-bottom: 30px;
  margin-left: -16px;
  margin-right: -16px;
`;

export const FiltersList = styled.ul`
  margin: 0;
  padding: 0 16px;
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  justify-content: start;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
`;

export const FiltersListItem = styled.li`
  margin: 0;
  padding: 0;
  margin-right: 10px;
`;

export const FilterValueList = styled.ul`
  list-style: none;
`;

export const FilterValueListItem = styled.li`
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  border-radius: 1000px;
  margin-bottom: 10px;

  &.active {
    background: rgba(39, 52, 66, 0.07);
  }
`;

export const FilterValueListContent = styled.p`
  display: block;
  padding: 10px 30px;
  margin: 0;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  white-space: nowrap;
  cursor: pointer;
`;

export const CloseButton = styled.span`
  position: absolute;
  top: 30px;
  right: 30px;
  text-indent: -5000px;
  width: 28px;
  height: 28px;
  background: rgba(39, 52, 66, 0.07);
  opacity: 0.8;
  display: inline-block;
  border-radius: 50%;
  background-image: url(${closeIcon});
  background-size: 11px 11px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`;
