import React from 'react';
import Modal from 'react-modal';

import {
  FiltersContainer,
  FiltersList,
  FiltersListItem,
  FilterValueList,
  FilterValueListItem,
  FilterValueListContent,
  CloseButton,
} from './styled';

const customStyles = {
  content: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
  },
};

class Filters extends React.Component {
  constructor() {
    super();

    this.state = {
      isModalOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  changeFilter() {}

  render() {
    const filters = [
      {
        id: 'name',
        name: 'name',
      },
    ];

    const items = [
      {
        id: 'name',
        name: 'name',
      },
    ];

    const modalTitle = 'modal title';

    return (
      <FiltersContainer>
        <FiltersList>
          {filters.map(filter => (
            <FiltersListItem key={filter.id} onClick={this.openModal}>
              {filter.name}
            </FiltersListItem>
          ))}
        </FiltersList>

        <Modal
          isOpen={this.state.isModalOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h1>{modalTitle}</h1>
          <FilterValueList>
            {items.map(item => (
              <FilterValueListItem key={item.id}>
                <FilterValueListContent onClick={this.changeFilter(item.value)}>
                  {/* {item.value} */}
                </FilterValueListContent>
              </FilterValueListItem>
            ))}
          </FilterValueList>
          <CloseButton onClick={this.closeModal}>X</CloseButton>
        </Modal>
      </FiltersContainer>
    );
  }
}
export default Filters;
